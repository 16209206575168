<template lang='pug'>
  .layoutbox
    .layoutbox-header
      //- .public.clearfix
        a.logo(@click='jumpYun')
          img(src="https://www.renshihr.com/static/images/headers-logo.png")
          //- img(src="https://t.renshihr.cn/static/images/headers-logo.png")
        div(style='height:40px;line-height:40px;display:inline-block;margin-left:62px;float:left;')
          span(style='font-size: 14px;color: rgb(45, 67, 123);font-family: 微软雅黑;font-weight: 800;margin-top:-10px;') — 档案管理
        .float-left(style='margin-left:70px;font-size:15px')
            ul
                li.float-left
                    ul.nav
                        li.li(@click='jumpHome("/")')
                            .lable 首页
                        li.li.relat
                            .select-box.lable 软件应用
                                img.h-icon(src="./images/hide.png")
                                img.s-icon(src="./images/show.png")
                            .mask
                            .hide-box
                                .title 专业应用
                                    img(src="./images/hot.png")
                                ul
                                    li(@click='jumpHome("/electronicSignature")')
                                        img(src="./images/1.png")
                                        | 电子签约
                                    li(@click='jumpHome("/easyPay")')
                                        img(src="./images/3.png")
                                        | 发薪管理
                                    li(@click='jumpHome("/dateManagement")')
                                        img(src="./images/5.png")
                                        | 日程管理
                                    li(@click='jumpHome("/enterpriseDocuments")')
                                        img(src="./images/6.png")
                                        | 企业发文
                                    li(@click='jumpHome("/enterpriseNetDisk")')
                                        img(src="./images/4.png")
                                        | 共享网盘
                                    li(@click='jumpHome("/notaryCertificate")')
                                        img(src="./images/8.png")
                                        | 公证存证
                                    li(@click='jumpHome("/fileManagement")')
                                        img(src="./images/7.png")
                                        | 档案管理
                                    li(@click="goUrl(2)")
                                        img(src="./images/2.png")
                                        | 知识变现
                                    li(@click='jumpHome("/jobRecruitment")')
                                        img(src="./images/zhaopi.png")
                                        | 招聘管理
                        li.li(@click='goUrl(1)')
                            .lable 知产平台
                        li.li.relat
                            .select-box.lable 企业服务
                                img.h-icon(src="./images/hide.png")
                                img.s-icon(src="./images/show.png")
                            .mask
                            .hide-box.com-app
                                ul
                                    li(@click='jumpHome("/enterpriseServices?weizhi=caishui")') 财税服务
                                    li(@click='jumpHome("/enterpriseServices?weizhi=zixun")') 咨询服务
                                    li(@click='jumpHome("/enterpriseServices?weizhi=zhaopin")') 招聘服务
                                    li(@click='jumpHome("/enterpriseServices?weizhi=renshi")') 人事服务
                                    li(@click='jumpHome("/enterpriseServices?weizhi=peixun")') 培训服务
                                    li(@click='jumpHome("/enterpriseServices?weizhi=waibao")') 外包服务
                        li.li.relat
                                .select-box.lable 解决方案
                                    img.h-icon(src="./images/hide.png")
                                    img.s-icon(src="./images/show.png")
                                .mask
                                .hide-box.com-app
                                    ul
                                        li(@click='jumpHome("/digitalEducation")') 数字教育
                                        li(@click='jumpHome("/videoTrial")') 视频庭审
                                        li(@click='jumpHome("/hospitalNurse")') 医院护工
                                        li(@click='jumpHome("/hotelCleaning")') 酒店保洁
                                        li(@click='jumpHome("/householdService")') 家政服务
                        li.li
                            .lable(@click="jumpHome('/aboutUs')") 关于我们

      .ctr
        .hd
          span 我的管理
        .content.clearfix
          Dropdown.company(v-if="userInfo.usertype != 2")
            .btn1(style="cursor:pointer")
              | {{ userInfo.usertype==1?'个人工作台':companyName }}
              Icon.has-more(type="md-arrow-dropdown")
            DropdownMenu.ivu-select(slot="list")
              DropdownItem
                a(@click="changePage(1)")  个人工作台
              DropdownItem(v-for="item in companyList" :key="item.company_id")
                a(@click="changeCompany(item)") {{item.company_name}}
              //- DropdownItem
              //-   a(@click="toApply()")
              //-     .tojion 加入企业

          //- 财务
          .finance(v-if="userInfo.usertype ==2")
            a.finance-btn
              | 财务
              Icon(type="md-arrow-dropdown" size="14" color="#FFF")
            .finance-content
              .price
                | 账户余额
                span {{userInfo.balance_text}}
                | 人是币
                a(:href="`${config.yun_domain}/console/finance/recharge?token=${global.token}`") 充值
              .link
                a(:href="`${config.yun_domain}/console/finance/extract?token=${global.token}`") 转出
                span |
                a(:href="`${config.yun_domain}/console/finance/invoice?token=${global.token}`") 开票
              .line
                a(style='margin-right:30px;' :href="`${config.yun_domain}/console/orders/buylist?token=${global.token}`") 我的订单
                //- a(:href="`${config.yun_domain}/console/order?token=${global.token}&s=1`") 待付款
                //-   span.num(style="color: #ff0000;") {{userInfo.daifukuan}}
              .line.bt
                a(:href="`${config.yun_domain}/console/finance/info?token=${global.token}`") 财务中心

          //- 公司
          Dropdown.company
            a.btn(href="javascript:void(0)")
              | {{userInfo.usertype ==2?userInfo.realname:userInfo.username}}
              Icon.has-more(type="md-arrow-dropdown")
            DropdownMenu.ivu-select(slot="list")
              DropdownItem
                a(:href="`${config.yun_domain}/console/account/info?token=${global.token}`") 账号信息
              DropdownItem(v-if="userInfo.usertype ==2")
                a(@click="handleSelectCompany") 切换企业
              DropdownItem
                a(@click="handleLoginout") 退出

          //- 消息
          .new
            a(:href="`${config.yun_domain}/console/message?token=${global.token}`")
              img(:src="`${publicPath}images/header/xiaoxi.png`")
              span(v-if="messageUnread > 0")
                i {{messageUnread >= 100 ? '99+' : messageUnread}}




    .layoutbox-menu
      Menu(:open-names="menuOpenName" :active-name="menuActiveName" accordion ref="menu")
        div(v-for="(row, index) in routeList" :key="index")
          Submenu(v-if="row.type == 'list'" :name="index")
            template(slot="title")
              i.icon.icon-xiaoyuandian
              span {{row.name}}

            MenuItem(v-for="(v, i) in row.list" :key="i" :name="index + '-' + i")
              a(:href="`${config.yun_domain}${v.path}?token=${global.token}&usertype=${userInfo.usertype}`") {{v.name}}

          MenuItem(v-else :name="index")
            a(style="padding-left: 24px!important;" :href="`${config.yun_domain}${row.path}?token=${global.token}&usertype=${userInfo.usertype}`")
              i.icon.icon-xiaoyuandian
              span {{row.name}}
        //- div(v-if="roleStaff['archive']!=0")
        div(v-if="userInfo.usertype!=1")
          Submenu(:name="6")
            template(slot="title")
              i.icon.icon-xiaoyuandian
              span 档案管理


            MenuItem(:name="'6-8'" v-if="roleStaff['archive/warn']!=0")
              router-link(:to="{name: 'ArchiveCount'}" )
                div.dot_box 档案统计及提醒
                    span(v-if="isShow").dot
            MenuItem(:name="'6-4'")
              router-link(:to="{name: 'archive_pickup'}" v-if="userInfo.usertype!=2") 档案提取
            MenuItem(:name="'6-0'" v-if="roleStaff['archive/content']!=0")
              router-link(:to="{name: 'ArchiveCreate'}") 录入档案
            MenuItem(:name="'6-1'" v-if="roleStaff['archive/extract']!=0")
              router-link(:to="{name: 'Archive'}")
                span.number(v-if="undohandls > 0") {{undohandls > 100 ? '...' : undohandls}}
                | 处理提档
            MenuItem(:name="'6-2'" v-if="roleStaff['archive/report']!=0")
              router-link(:to="{name: 'Retrieval'}") 检索及报表
            MenuItem(:name="'6-11'"  v-if="roleStaff['archive/file_recycle']!=0")
              router-link(:to="{name: 'archive_recycle'}" ) 文件回收站
            //- MenuItem(:name="'6-3'" v-if="userInfo.owned_auths.includes('archive_auth')")
            //-   router-link(:to="{name: 'Auth'}") 授权管理

    .layoutbox-content
      slot

    Modal(
      v-model="isModal"
      footer-hide
      title="请选择企业账号"
      class="modal"
    )
      .modal-content.clear
        .item(
          :class="[selected_company_id == v.company_id ? 'active' : '']"
          v-for="(v, i) in userInfo.bind_company_list"
          @click="handleSelectCompanyId(v.company_id)"
        ) {{v.company_name}}

    Modal(
      v-model="isModalCode"
      footer-hide
      width="300"
      title="请填写验证码"
      class="modal"
      @on-cancel="code = '';company_id = selected_company_id;"
    )
      div
        .modal-group
          Input(:value="userInfo.company_mobile.substr(0,3) + '****' + userInfo.company_mobile.substr(7)" size="large" disabled v-if="userInfo.company_mobile")
        .modal-group
          Input(v-model="code" size="large" placeholder="请输入验证码")
          Button(type="primary" @click="handleGetCode") {{code_text}}
        .modal-group
          Button(class="btn" type="primary" @click="handelLoginCode" size="large") 确定

</template>

<script type="text/javascript">
import routeList from '../views/route-list.js'
import config from 'config'
export default {
  name: 'LayoutPage',

  watch: {
    '$route' () {
      let { openName = 6, activeName = '6-0' } = this.$route.meta;
      this.menuOpenName = [openName]; //[2]
      this.menuActiveName = activeName; //'2-0'
      this.$nextTick(res => {
          this.$refs.menu.updateActiveName()
          this.$refs.menu.updateOpened()
      })
    }
  },
  created() {
    // this.userInfo.usertype != 2 ? this.getCompanyList() : ''
    this.handleUnreadCount();
    this.selected_company_id = this.userInfo.id
  },

  mounted () {
    this.routeList = routeList[this.userInfo.usertype == 2 ? 'company' : 'staff'];
    let { openName = [6], activeName = '6-0' } = this.$route.meta;
    this.menuOpenName = openName; //[2]
    this.menuActiveName = activeName; //'2-0'

    this.$nextTick(res => {
        this.$refs.menu.updateActiveName()
        this.$refs.menu.updateOpened()
    })
    if(!this.roleStaff['archive/extract']!=0) return
    this.handleGetUndohandls()
    let _ = this;
    eventBus.$on('on-undohandls', () => {
      _.handleGetUndohandls()
    })
  },

  methods: {

    handleGetUndohandls () {
        this.$api.archives.archivesHandleList({
            page: this.page,
            page_number: 1
        }).then(res => {
            if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})
            this.undohandls = res.obj.undohandls
        }).catch(err => {
            console.log(err)
        })
    },
    // 获取未读消息数
    handleUnreadCount() {
      this.$api.user.unreadCount().then((res) => {
          if (res.status_code == 0){
            this.messageUnread = res.obj.count
          }
        })
        .catch((err) => {
          this.loading = false
          console.log(err)
        });
    },
    goUrl(num) {
      if (num == 1) {
        window.open(config.shiquanshimei + `?token=${_global.token}`)
      }
      if (num == 2) {
        window.open(config.wenhui + `?token=${_global.token}`)
      }
    },
    jumpYun() {
      window.location.href = config.yun_domain+ `?token=${_global.token}`;
     },

    jumpHome(link) {
       window.location.href = config.yun_domain+link+ `?token=${_global.token}`;
    },

    handleLoginout () {
      this.$api.user.loginout().then(res => {
          if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})
          window.location.href = config.yun_domain
      }).catch(err => {
          this.loading = false
          console.log(err)
      })
    },

    // 切换企业
    handleSelectCompany  () {
      let company = this.userInfo.bind_company_list;
      if (company.length < 1) return this.$Message.info({content: '暂无可切换的企业账号'})
      this.isModal = true
    },
    // 加入其他企业
    toApply() {
            this.$api.related_company.general_relate_companyList({
                mobile:this.userInfo.mobile,
            }).then(res => {
                if( res.status_code === 0 ){
                    let old = window.location.href;
                    window.location.href = `${config.yun_domain}`+ '/invite/apply/' + this.userInfo.id + '/' + this.userInfo.mobile + "?from_url=" + encodeURI(old);
                } else {
                    this.$alert({type: 'error', text: res.status_msg})
                    return
                }
            })
        },
    handleSelectCompanyId (id) {
      if (id == this.company_id) return this.$Message.warning({content: '当前选择已经是该企业账户'});
      this.company_id = id;
      this.isModalCode = true
    },
    handelLoginCode () {
      if(this.isAjax) return
      if (this.code == '') return this.$Message.warning({content: '验证码格式不正确'});
      this.isAjax = true
      this.$api.user.change_login({
        token: this.userInfo.token,
        company_id: this.company_id,
        code: this.code
      }).then(res => {
        this.isAjax = false
        if (res.status_code !== 0) return this.$Message.warning({content: res.status_msg});
        cookie.setCookie('token', res.obj.token)
        setTimeout(() => {
            window.location.reload()
        }, 500)
      })
    },

    // 切换个人工作台
    changePage() {
      localStorage.setItem('usertype',1)
      // window.location.href= config.yun_domain +'/console?token='+_global.token
      window.location.href= window.location.origin

    },

    // 企业切换获取验证码
    handleGetCode () {
      if (this.isAjax || this.is_code_time) return
      this.isAjax = true;
      this.$api.user.sms({
          mobile: this.userInfo.mobile
      }).then((res) => {
          this.isAjax = false
          if (res.status_code != 0) return this.$Message.warning({content: res.status_msg});
          this.handleSetTime()
      }, (err) => {
          this.isAjax = false;
      })
    },
    handleSetTime () {
      if (this.time == 0) {
        this.code_text = '获取验证码'
        this.is_code_time = false
        this.time = 60
        this.code = ''
        clearTimeout(this.timer);
        return;
      }
      this.is_code_time = true
      --this.time
      this.timer = setTimeout(() => {
        this.code_text = `${this.time}S`
        this.handleSetTime()
      }, 1000)
    },
    // 获取关联企业列表
    getCompanyList () {
      this.loading = true;
      this.$api.related_company.getCompanyList().then(res => {
          this.loading = false
          if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})
          this.companyList = res.list

      }).catch(err => {
          this.loading = false
          console.log(err)
      })
    },
    // 切换员工工作台
    changeCompany(data) {
      this.isAjax = true
      this.$api.related_company.companyChange({
        company_id: data.company_id,
      }).then(res => {
        this.isAjax = false
        if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})
        localStorage.setItem('usertype', 3)
        // localStorage.setItem('company_name', data.company_name)
        localStorage.setItem('current_company_id', data.company_id)
        window.location.href='/'
      })
    },
  },

  data () {
    return {
      isShow:false,
      publicPath: process.env.BASE_URL,
      config: config,
      global: _global,
      userInfo: _global.userInfo,
      undohandls: 0,

      menuActiveName: '',
      menuOpenName: [],

      isModal: false,
      isModalCode: false,
      company_id: '',
      selected_company_id: 0,

      code: '',
      code_text: '获取验证码',
      time: 60,
      timer: null,
      is_code_time: false,

      isAjax: false,
      isRouterAlive: true,
      routeList: [],
      companyName:_global.userInfo.company_name,
      companyList: _global.company_list,
      messageUnread:0,
      roleStaff:JSON.parse(localStorage.getItem('staff_auth_info')),
    }
  }
}
</script>


<style lang="less" scoped>
.dot_box{
  display: flex;
  align-items: center;
  .dot{
    display: inline-block;
    width: 6px;
    height: 7px;
    border-radius: 50px;
    background: #EC757D;
    margin-left: 10px;
  }
}





.layoutbox                              {
  &-header                              {position: fixed;top: 0;left: 0;width: 100%;z-index: 100;
    .public                             {height: 40px;padding: 0 20px;background: #FFF;line-height: 40px;
      .logo                             {position: fixed;display: table-cell;
        img                             {display: inline-block;width: auto;margin-top: 12px;margin-right: 8px;}
      }
      .nav                              {float: right;
        a                               {font-size: 12px;color: #333;display: inline-block;margin-left: 14px;}
      }
      .float-left{
        float: left;

        ul.nav {
        // @media screen and (max-width: 940px) {
        //     display: none;
        //     // background: turquoise;
        // }
        display: flex;
        .li {

            margin-right: 2.083vw;
            transition: 0.3s;
            color: #20242b;
            & /deep/ a {
                color: #20242b;
            }
            .mask {
                position: absolute;
                top: 20px;
                left: 0;
                width: 100%;
                height: 100px;
                display: none;
                &:hover {
                }
            }
            &:hover .mask {
                display: block;
            }
            &:hover .lable {
                color: #2b68e7;
                cursor: pointer;
            }
            .lable{
              font-size: 15px;
            }
            &.relat {
                position: relative;
                .select-box {
                    display: flex;
                    align-items: center;
                    img {
                        width: 10px;
                        margin-left: 7px;
                        transition: 0.3s;
                    }
                }

                .hide-box {
                  font-size: 15px!important;
                    visibility: hidden;
                    opacity: 0;
                    transition: 0.3s;
                    z-index: 8888;
                    position: absolute;
                    left: 10px;
                    top: 50px;
                    padding: 10px 30px 20px;
                    background: #fff;
                    border-radius: 5px;
                    box-shadow: 0px 10px 30px 0px rgba(0, 37, 102, 0.1);
                    width: 600%;


                    .title {
                      font-size: 15px;
                        display: flex;
                        align-items: center;
                        padding-bottom: 10px;
                        border-bottom: 1px solid #dadada;
                        margin-bottom: 10px;
                        img {
                            margin-left: 5px;
                            width: 30px;
                        }
                    }
                    > ul {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        > li {
                            display: flex;
                            font-size: 15px;
                            // padding: 10px 0px;
                            padding-right: 36px;
                            align-items: center;
                            cursor: pointer;
                            &:hover {
                                color: #2b68e7;
                            }
                            img {
                                width: 20px;
                                margin-right: 5px;
                            }
                        }
                    }
                    &.com-app {
                        width: 300%;
                        padding: 20px 20px;
                        > ul {
                            > li {
                                padding: 0px 10px;
                            }
                        }
                    }
                }
                &:hover .hide-box {
                    left: 0px;
                    visibility: visible;
                    opacity: 1;
                }
                .s-icon {
                    visibility: hidden;
                    opacity: 0;
                    transform: rotate(180deg);
                    transition: 0.3s;
                    display: none;
                }
                &:hover .s-icon {
                    visibility: visible;
                    opacity: 1;
                    transform: rotate(0deg);
                    display: block;
                }
                &:hover .h-icon {
                    display: none;
                    visibility: hidden;
                    opacity: 0;
                }
            }
        }
    }
      }

    }
    .ctr                                {height: 54px;background: #004481;width: 100%;
      .hd                               {color: #FFF;height: 54px;width: 216px;padding-left: 36px;background: #003a6c;float: left;
        span                            {font-size: 16px;line-height: 54px;font-weight:800;margin-left: 10px;}
      }
    }
    // .content                            {float: right;display: inline-flex;height: 54px;width: 380px;align-items: center;margin-right:22px;padding-right:20px;
    .content                            {float: right;display: inline-flex;height: 54px;width: 380px;justify-content:flex-end;padding-right:30px;
      .vip                              {display: flex;align-items: center;justify-content: center;padding: 0 16px;background: #FFF;border-radius: 4px;white-space: nowrap;line-height: 32px;
        img                             {width: 16px;margin-right: 2px;}
        div                             {font-size: 12px;color: #333;}
        &:hover div                    {color: @primary;}
      }

      .finance                          {float: left;width: 100px;height: 54px;position: relative;z-index: 100;
        &-btn                           {display: block;line-height: 54px;color: #FFF;text-align: center;}
        &-content                       {position: absolute;top: 54px;right: 0;background: #FFF;z-index: 1;padding: 10px 25px;box-shadow: 0 2px 3px #ddd;border: 1px solid #d9d9d9;display: none;
          div                           {color: #343434;width: 354px;display: block;line-height: 20px;margin: 14px 0;
            &.price                     {
              span                      {font-size: 24px;margin: 0 6px;font-weight: 600;}
              a                         {float: right;}
            }
            &.link                      {
              a                         {color: #343434;
                &:hover                 {color: @primary;}
              }
              span                      {margin: 0 14px;color: #999;display: inline-block;}
            }
            &.line                      {
              a                         {color: #343434;
                &:hover                 {color: @primary;}
              }
              &.bt                      {border-top: 1px solid #d9d9d9;padding-top: 14px;}
            }
          }
        }
        &:hover                         {background: #003a6c;
          .finance-content              {display: block;}
        }
      }
      .company                          {
        .tojion{
                color: #0092FF;
            }
        .btn                            {height: 54px;max-width: 200px;line-height: 54px;color: #FFF;padding: 0 10px;display: inline-block;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;
          &:hover                       {background: #003a6c;}
        }
        .btn1                           {height: 54px;line-height: 54px;color: #FFF;padding: 0 10px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;
          &:hover                       {background: #003a6c;}
        }
        .has-more                       {height: 54px;}
        .ivu-select                     {
          .ivu-select-dropdown          {border-radius: 0!important;margin: 0!important;padding: 0!important;}
          a                             {color: #343434;text-align: center;height: 22px;line-height: 22px;display: block;}
        }
      }
      .new                              {height: 54px;width: 54px;display: flex;align-items: center;justify-content: center;position: relative;
        img                             {width: 24px;}
        span                            {position: absolute;top: 10px;right: 0px;min-width: 20px;height: 12px;border-radius: 30px;font-size: 10px;background: red;color: #FFF;padding: 0 4px;line-height: 12px;
          i                             {transform: scale(0.8)!important;font-style: normal;font-size: 12px!important;line-height: 12px;}
        }
      }
    }
  }

  &-menu                                {position: fixed;top: 54px;left: 0;width: 216px;bottom: 0;background: #f5f5f5;box-shadow: 0 0 4px #d9d9d9;overflow-y: auto;}

  &-content                             {padding: 54px 0 0 216px;overflow: hidden;
    > div                               {overflow: auto;width: 100%;}
  }
}

.number                 {position: absolute;top: 6px;right: 86px;display:inline-block;min-width: 22px;height: 18px;background: red;color: #FFF;border-radius: 8px;font-size: 12px;text-align: center;}

.modal                  {
    &-content           {max-height: 400px;overflow-y: scroll;}
    .item               {display: block;margin-bottom: 6px;padding: 4px 10px;cursor: pointer;font-size: 14px;
        &:hover         {color: #57a3f3;}
        &.active        {color: #57a3f3;}
    }
    &-group             {display: flex;margin-bottom: 15px;
        input           {flex: 1;}
        button          {width: 130px;margin-left: 10px;
            &.btn       {width: 100%;margin: 0;}
        }
    }
}
</style>

<style lang="less">
.ivu-menu                               {background: none!important;width: 206px!important;margin: 0 5px!important;
  .router-link-exact-active             {background: #005aa9;color: #FFF!important;border-radius: 4px!important;}
  ul                                    {margin: 0!important;}
  .ivu-menu-submenu-title > i,
  .ivu-menu-submenu-title span > i      {margin-right: -8px;font-size: 18px;color: #999;
    &.icon                              {margin: 0!important;font-size: 14px;margin-right: 4px;}
  }
  .icon                                 {margin: 0!important;font-size: 14px;margin-right: 4px;}
}
.ivu-menu-vertical.ivu-menu-light:after {display: none!important;}
.ivu-menu-vertical .ivu-menu-submenu-title {color:#343434!important;}
.ivu-menu-vertical .ivu-menu-item {color:#343434!important;padding: 0!important;border-radius: 4px!important;}
.ivu-menu-vertical .ivu-menu-item a, .ivu-menu-vertical .ivu-menu-submenu-title a {color: #343434;}

.ivu-menu-vertical .ivu-menu-item:hover {background: #e0e6ea;}
.ivu-menu-vertical .ivu-menu-item a {padding: 10px 24px 10px 43px!important;display: block;}
.ivu-menu-submenu .ivu-menu-item a {color: #455a64;}
.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu) {background: #e0e6ea!important;}
.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu):after {display: none!important;}
.ivu-menu-vertical .ivu-menu-item:hover, .ivu-menu-vertical .ivu-menu-submenu-title:hover {background: #e0e6ea;}
.ivu-dropdown .ivu-select-dropdown {
    overflow: auto!important;
    max-height: 50vh!important;
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #999;
    }
}

</style>