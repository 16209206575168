export default {
    'staff': [
        {
            type: 'link',
            name: '员工工作台',
            icon: 'xiaoyuandian',
            path: '/console-staff/',
            exact: 'index'
        },
        {
            type: 'link',
            name: '订单中心',
            exact: 'order',
            icon: 'xiaoyuandian',
            path: '/console-staff/order'
        },
    ],
    'company': [
        {
            type: 'link',
            name: '我的工作台',
            exact: 'index',
            icon: 'xiaoyuandian',
            path: '/console/'
        },
        {
            type: 'link',
            name: '我的企业应用',
            exact: 'my-app',
            icon: 'xiaoyuandian',
            path: '/console/my-app',
        },
        {
            type: 'link',
            name: '我的订单',
            exact: 'order',
            icon: 'xiaoyuandian',
            path: '/console/order'
        },

        {
            type: 'list',
            name: '财务中心',
            icon: 'xiaoyuandian',
            label: 'finance',
            unfold: false,
            list: [{
                type: 'link',
                name: '财务账户信息',
                exact: 'finance-info',
                icon: '',
                path: '/console/finance/info',
            },
            {
                type: 'link',
                name: '转出',
                exact: 'extract',
                icon: '',
                path: '/console/finance/extract'
            },
            {
                type: 'link',
                name: '充值',
                exact: 'recharge',
                icon: '',
                path: '/console/finance/recharge'
            },
            {
                type: 'link',
                name: '开票管理',
                exact: 'invoice',
                icon: '',
                path: '/console/finance/invoice'
            }]
        },
        {
            type: 'list',
            name: '企业管理',
            icon: 'xiaoyuandian',
            label: 'company',
            unfold: false,
            list: [{
                type: 'link',
                name: '企业认证',
                exact: 'company-verify',
                icon: '',
                path: '/console/company/verify'
            },
            {
                type: 'link',
                name: '企业基本信息',
                exact: 'company-info',
                icon: '',
                path: '/console/company/info',
            },
            // {
            //     type: 'link',
            //     name: '组织架构',
            //     exact: 'structure',
            //     icon: '',
            //     // path: {
            //     //     name: 'structure',
            //     //     params: {
            //     //         tab: 1
            //     //     }
            //     // },
            //     path: '/console/structureModule/structure',
            //     routeMark: 1
            // },
            // {
            //     type: 'link',
            //     name: '员工管理',
            //     exact: 'structure',
            //     icon: '',
            //     // path: {
            //     //     name: 'structure',
            //     //     params: {
            //     //         tab: 0
            //     //     }
            //     // },
            //     path: '/console/structureModule/structure',
            //     routeMark: 0
            // },
            // {
            //     type: 'link',
            //     name: '应用授权管理',
            //     exact: 'app-impower',
            //     icon: '',
            //     path: '/console/company/app-impower'
            // }
            ]
        },
        {
            type: 'list',
            name: '组织架构',
            icon: 'xiaoyuandian',
            label: 'organization',
            unfold: false,
            list: [{
                type: 'link',
                name: '成员与部门',
                icon: '',
                path: '/console/structureModule/structure',
                label: 'organization/staff_dept',
                exact: 'structure'
            }, {
                type: 'link',
                name: '角色设置',
                icon: '',
                path: '/console/structureModule/roleSetting',
                label: 'organization/role',
                exact: 'roleSetting',
            }, {
                type: 'link',
                name: '职位',
                icon: '',
                path: '/console/structureModule/position',
                label: 'organization/position',
                exact: 'position',
                },
                {
                type: 'link',
                name: '职级',
                icon: '',
                path: '/console/structureModule/positionClass',
                label: 'organization/rank',
                exact: 'positionClass',
                }
            ]
        },
        {
            type: 'list',
            name: '账号设置',
            icon: 'xiaoyuandian',
            label: 'account',
            unfold: false,
            list: [{
                type: 'link',
                name: '账号信息',
                exact: 'account-info',
                icon: '',
                path: '/console/account/info',
            },
            {
                type: 'link',
                name: '密码修改',
                exact: 'password',
                icon: '',
                path: '/console/account/password'
            }]
        }
    ]
}
