import { request, config } from '@/utils'

const api = {

    login () {  // 登录
        return request.post(`${config.apiurl}/login`)
    },

    // userInfo () {  // 获取登录用户信息
    //     return request.post(`${config.apiurl}/user/info`)
    // },

    loginout () {  // 获取登录用户信息
        return request.post(`${config.apiurl}/logout`)
    },

    change_login (data) {  // 切换登录
        return request.post(`${config.apiurl}/user/company/change/login`, data)
    },

    sms (data) {  // 发送短信验证码
        return request.post(`${config.apiurl}/sms/send`, data)
    },
    userInfo (params) {  // 获取登录用户信息
        return request.post(`${config.yun_domain_v1}/api/user/info`,params)
    },
    unreadCount() {  // 获取未读消息数
        return request.post(`${config.yun_domain_v1}/api/v1/message/my/unread_count`)
    },

    departmentmanage (data) {  // 获取企业
        return request.post(`${config.yun_domain_v1}/api/v1/company_manage/info/get`, data)
    },
    departmeperson (data) {  // 获取个人
        return request.post(`${config.yun_domain_v1}/api/v1/person_manage/info/get`, data)
    },

}

export default api;