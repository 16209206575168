import {config,request} from '@/utils'

const api = {

    // 获取个人关联企业列表接口
    getCompanyList () {
        return request.post(`${config.yun_domain_v1}/api/v1/person_manage/related_company/list`)
    },
    // 切换个人关联企业接口
    companyChange (params) {
        return request.post(`${config.yun_domain_v1}/api/v1/person_manage/related_company/change`,params)
    },
    general_relate_companyList(params) {
        return request.post(`${config.yun_domain_v1}/api/general/person/relate_company/list`, params)
    },


}

export default api;