
const config = {
    development: {
        apiurl: '/~api',	// ~api：代理标识
        proxy: 'http://archive_api.service.consul',
        yun_domain: 'http://yun15.service.consul',//'https://t.renshihr.cn/',
        yun_domain_v1: 'http://yun.service.consul',
        wenhui: "https://wenhui.renshihr.cn",		//原文荟
	    shiquanshimei: 'http://ippt8.renshihr.cn', //识权识美
        env: 'dev'
    },
    local: {
        apiurl: 'http://archive_api.service.consul',
        yun_domain: 'http://yun15.service.consul',
        yun_domain_v1: 'http://yun.service.consul',
        wenhui: "https://wenhui.renshihr.cn",		//原文荟
	    shiquanshimei: 'http://ippt8.renshihr.cn', //识权识美
        env: 'local'
    },
    test: {
        apiurl: 'http://archiveapi.renshihr.cn',
        yun_domain: 'https://t.renshihr.cn',
        yun_domain_v1: 'https://tpa.renshihr.cn',
        wenhui: "https://wenhui.renshihr.cn",		//原文荟
	    shiquanshimei: 'http://ippt8.renshihr.cn', //识权识美
        env: 'test'
    },
    production: {
        apiurl: 'http://archiveapi.renshihr.com',
        yun_domain: 'https://www.renshihr.com',
        yun_domain_v1: 'https://api.renshihr.com',
        wenhui: "http://ywh.renshihr.com",  //原文荟
	    shiquanshimei: 'https://www.ippt8.com', //识权识美
        env: 'production'
    }
}
module.exports = config[process.env.VUE_APP_CONFIG || 'development']