import '@/assets/style/base.less';
import '@/assets/style/common.less';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/iview/iview.js'
import config from 'config'
import {
  cookie
} from '@/utils'
import * as axios from '@/utils/request'
import api from '@/api'
import publicConfig from '../public/js/config.js'

Vue.config.productionTip = false
Vue.use(axios)
Vue.prototype.$api = api;

// Vue.use(layout)
window.eventBus = new Vue();
window._vue = null
window._global = {
  isLogin: false,
  token: cookie.getUrlAllParams().token || cookie.getCookie('token'),
  route: '',
  userInfo: {},
  company_list:[],
  auths: [],
  auth_key: {
    'archive_entry': '/create',
    'archive_deal': '/archive',
    'archive_search': '/retrieval',
    'archive_pickup': '/extract',
    'archive_auth': '/auth',
    'ArchiveCount': '/count'
  },
  env: process.env.NODE_ENV == "production",
  publicConfig: publicConfig
}
localStorage.setItem('usertype',cookie.getUrlAllParams().usertype || localStorage.getItem('usertype'),)
localStorage.setItem('current_company_id',cookie.getUrlAllParams().current_company_id || localStorage.getItem('current_company_id'),)

// 入口，获取登录状态
setTimeout(async () => {
  Vue.prototype.$Message.loading({
    content: 'Loading...',
    duration: 0
  })
  if(!_global.token) return window.location.href=config.yun_domain+'/acctlogin?referer_url='+window.location.origin
  const getUserInfo = new Promise(async function (resolve, reject) {
    const result = await api.user.userInfo({
      token: _global.token
    })
    if (result.status_code != 0) return Vue.prototype.$Message.warning({ content: result.status_msg })
    resolve(result);
  });
  api.auth.privilegeInfo({
    token: _global.token
  }).then((res) => {
    Vue.prototype.$Message.destroy();
    // if (res.status_code != 0) return Vue.prototype.$Message.warning({ content: res.status_msg,duration: 0 })
    if (res.status_code != 0) {
      return Vue.prototype.$Modal.confirm({
        title: '提示',
        content: res.status_msg,
        loading: true,
        cancelText:'',
        onOk: () => {
          Vue.prototype.$Modal.remove();
          window.location.href=`${config.yun_domain}/console?token=${_global.token}`
        },
        onCancel: () => {
          Vue.prototype.$Modal.remove();
          window.location.href=`${config.yun_domain}/console?token=${_global.token}`
        },
    });
    }
    if (localStorage.getItem('usertype') == 1) {
      localStorage.setItem('staff_auth_info', JSON.stringify({}))
    } else {
      localStorage.setItem('staff_auth_info', JSON.stringify(res.obj.auth_info))
    }
    cookie.setCookie('token', _global.token)
    // 员工身份
    if (localStorage.getItem('usertype') != 2) {
      const companyList = new Promise(async function (resolve, reject) {
        const result = await api.related_company.getCompanyList({
          token: _global.token
        })
        if (result.status_code != 0) return Vue.prototype.$Message.warning({content: result.status_msg})
        _global.company_list = result.list
        resolve(result);
      });
      api.user.departmeperson({
        token: window._global.token,
      }).then(async (rest) => {
        if (rest.status_code == 0) {
          Vue.prototype.$Message.destroy();
          if (localStorage.getItem('usertype') == 1) {
            Vue.prototype.$Modal.warning({
              title:'提示',
              content: '人是云档案管理应用目前仅开放给企业用户使用，目前该用户尚未关联企业，请先进入人是云平台操作加入企业后使用或切换到已加入企业的员工工作台中使用。'
          });
          }
          // Vue.prototype.$Message.warning({ content: '人是云档案管理应用目前仅开放给企业用户使用，目前该用户尚未关联企业，请先进入人是云平台操作加入企业后使用或切换到已加入企业的员工工作台中使用。', duration: 0 })
          // if(res.obj.auth_info['archive']==0)  Vue.prototype.$Message.warning({ content: '无权限访问,请联系企业管理员添加该应用权限',duration: 0 })
          _global.userInfo = rest.obj
          _global.isLogin = true
          _global.userInfo.usertype = localStorage.getItem('usertype');
          _global.userInfo.realname = rest.obj.name,
          _global.userInfo.mobile = rest.obj.person_mobile
          _global.userInfo.company_name = rest.obj.company_name

          const companyListInfo = await companyList;
          companyListInfo.list.map(item => {
            if (item.company_id == Number(localStorage.getItem('current_company_id'))) {
              _global.userInfo.company_name = item.company_name;

            }
          })
          siteInit();

        }
      })
    // 企业身份
    } else {
      api.user.departmentmanage({
        token: window._global.token
      }).then(async (rest) => {
        if (rest.status_code == 0) {
          Vue.prototype.$Message.destroy();
          _global.isLogin = true
          _global.userInfo.usertype = localStorage.getItem('usertype');
          _global.userInfo.realname = rest.obj.companyname
          _global.userInfo.bind_company_list = rest.obj.mobile_bind_company_users
          _global.userInfo.company_mobile = rest.obj.company_mobile
          _global.userInfo.id = rest.obj.id
          const get_userInfo = await getUserInfo;
          _global.userInfo.balance_text=get_userInfo.obj.balance_text
          _global.userInfo.daifukuan=get_userInfo.obj.daifukuan
          siteInit();

        }
      })

    }
  }).catch(err => {
    cookie.delCookie()
    siteInit();
  })
}, 100)

// siteInit();


// 全局初始化
function siteInit() {
  window._vue = new Vue({
    router,
    render: h => h(App)
  }).$mount('#app')
}