import Cookies from 'js-cookie'
import _ from 'lodash';

export default {
    /**
     * 设置 Cookie
     * @param {String} name     cookie 名称
     * @param {String} value    cookie 值
     * @param {Number} Days     cookie 过期时间
     */
    setCookie (name, value, Days = 7) {
        Cookies.set(name, value, {expires: Days})
    },

    /**
     * 读取 Cookie
     * @param {String} name     cookie 名称
     */
    getCookie (name = 'token') {
        return Cookies.get(name);
    },

    /**
     * 删除 Cookie
     * @param {String} name     cookie 名称
     */
    delCookie(name = 'token'){
        Cookies.remove(name);
    },

    /**
     * 获取url中传递的所有参数对象
     */
    getUrlAllParams () {
        // 解决乱码问题
        let url = decodeURI(window.location.href)
        let res = {}
        let url_data = _.split(url, '?').length > 1 ? _.split(url, '?')[1] : {} ;
        if (!url_data) return {}
        let params_arr = _.split(url_data, '&')
        _.forEach(params_arr, function(item) {
                let key = _.split(item, '=')[0]
                let value = _.split(item, '=')[1]
                res[key] = value
        }); 
        
        return res
    }
}