<template lang='pug'>
layout-page
    div(style="padding: 10px;")
        keep-alive
          router-view(v-if="$route.meta.keepAlive && isRouterAlive")
        router-view(v-if="!$route.meta.keepAlive && isRouterAlive")


</template>

<script type="text/javascript">
import layoutPage from '@/layout/layout-page.vue'
export default {
  name: 'App',

  components: {
      layoutPage
  },

  provide () {
    return {
      reload: this.reload
    }
  },

  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    }
  },

  data () {
    return {
        isRouterAlive: true,
    }
  }
}
</script>
