"use strict";
export default [

    {   // 授权
        path: '/auth',
        meta: {untoken: true},
        component: () => import('@/views/auth/index.vue'),
        children: [
            {
                path: '',
                name: 'Auth',
                meta: {openName: 6, activeName: '6-3', name: 'archive_auth'},
                component: () => import('@/views/auth/index/index.vue')
            },
            {
                path: 'add',
                name: 'AuthAdd',
                meta: {openName: 6, activeName: '6-3', name: 'archive_auth'},
                component: () => import('@/views/auth/add/index.vue')
            }
        ]
    },

    {   // 搜索
        path: '/search',
        meta: {untoken: true},
        component: () => import('@/views/search/index.vue'),
        children: [
            {
                path: '',
                name: 'Search',
                meta: { name: 'archive_common'},
                component: () => import('@/views/search/index/index.vue')
            }
        ]
    },

    {   // 检索及报表
        path: '/retrieval',
        meta: {untoken: true},
        component: () => import('@/views/retrieval/index.vue'),
        children: [
            {
                path: '',
                name: 'Retrieval',
                meta: {openName: 6, activeName: '6-2', name: 'archive_search'},
                component: () => import('@/views/retrieval/index/index.vue')
            },
            {
                path: 'detail',
                name: 'RetrievalDetail',
                meta: {openName: 6, activeName: '6-2', name: 'archive_common'},
                component: () => import('@/views/retrieval/detail/index.vue')
            }
        ]
    },

    // {   // 档案统计及提醒
    //     path: '/count',
    //     name: 'ArchiveCount',
    //     meta: {untoken: true},
    //     component: () => import('@/views/archive/count/index.vue'),
    //     children: [
    //         {
    //             path: 'count',
    //             name: 'ArchiveCount',
    //             meta: {openName: 6, activeName: '6-8', name: 'ArchiveCount'},
    //             component: () => import('@/views/retrieval/detail/index.vue')
    //         }
    //     ]
    // },

    {   // 处理提档
        path: '/',
        redirect: '/create',
        meta: {untoken: true},
        component: () => import('@/views/archive/index.vue'),
        children: [
            {   //处理提档
                path: 'archive',
                name: 'Archive',
                meta: {openName: 6, activeName: '6-1', name: 'archive_deal'},
                component: () => import('@/views/archive/index/index.vue')
            },
            {   // 录入档案
                path: 'create',
                name: 'ArchiveCreate',
                meta: {openName: 6, activeName: '6-0', name: 'archive_entry'},
                component: () => import('@/views/archive/create/index.vue')
            },
            // 档案提取
            {
                path: 'extract',
                name: 'archive_pickup',
                meta: {openName: 6, activeName: '6-12', name: 'archive_pickup'},
                component: () => import('@/views/archive/extract/index.vue')
            },
            {   // 档案统计及提醒
                path: 'count',
                name: 'ArchiveCount',
                meta: {openName: 6, activeName: '6-8', name: 'ArchiveCount'},
                component: () => import('@/views/archive/count/index.vue'),
            },
            {   // 档案统计详情
                path: 'count/detail',
                name: 'ArchiveCountDetail',
                meta: {openName: 6, activeName: '6-9', name: 'ArchiveCountDetail'},
                component: () => import('@/views/archive/count_detail/index.vue')
            },
            {   // 文件回收站
                path: 'recovery',
                name: 'archive_recycle',
                meta: {openName: 6, activeName: '6-11', name: 'archive_recycle'},
                component: () => import('@/views/archive/recovery/index.vue')
            },
        ]
    }






];
