import { request, config } from '@/utils'

const api = {

    staffAuthList (data) {  // 获取员工授权列表
        return request.post(`${config.apiurl}/staff/auth/list`, data)
    },

    departmentList () {  // 获取部门列表
        return request.post(`${config.apiurl}/department/list`)
    },

    staffList (data) {  // 获取员工列表
        return request.post(`${config.apiurl}/staff/list`, data)
    },

    roleList () {  // 获取授权角色列表
        return request.post(`${config.apiurl}/staff/role/list`)
    },

    staffAuth (data) {  // 设置员工授权
        return request.post(`${config.apiurl}/staff/auth`, data)
    },

    staffUnauth (data) {  // 删除员工授权
        return request.post(`${config.apiurl}/staff/unauth`, data)
    },

    authTmpl (data) {  // 获取授权模板数据
        return request.post(`${config.apiurl}/staff/auth/tmpl`, data)
    },
    privilegeInfo (data) {  // 获取档案管理应用权限列表
        return request.post(`${config.apiurl}/privilege/info`, data)
    },




}

export default api;