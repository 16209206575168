"use strict";
import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import { config } from '@/utils'
Vue.use(VueRouter)

const router = new VueRouter({
    // mode: 'hash',
    mode: 'history',
    routes: routes,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
});
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}



router.beforeEach(async (to, from, next) => {
    const roleStaff = JSON.parse(localStorage.getItem('staff_auth_info'))
    // _global.route = to;
    // if (to.meta.untoken) return next()
    // if (_global.token) return next()
    // if (!to.query.token) window.location.href = `${config.apiyun_domain}/member/login?referer_url=${encodeURIComponent(window.location.href)}`

    // 员工身份，员工路由则直接进入，否则跳转指定员工路由
    // if (_global.token && _global.userInfo.usertype == 1) {
    //     if (to.meta.ut.indexOf(1) >= 0) {
    //         return next();
    //     } else {
    //         return next('/extract');
    //     }
    // }
    // if (_global.token && _global.userInfo.usertype == 2) {
    //     if (to.meta.ut.indexOf(2) >= 0) {
    //         return next();
    //     } else {
    //         return next('/create');
    //     }
    // }
    if (to.path.indexOf('/create')>-1 && localStorage.getItem('usertype')==1) {
        return next(false)
    }
    if (to.path.indexOf('/count')>-1 && roleStaff['archive/warn']==0) {
        return next(false)
    }
    if (to.path.indexOf('/create')>-1 && roleStaff['archive/content']==0) {
        return next(false)
    }
    if (to.path.indexOf('/archive')>-1 && roleStaff['archive/extract']==0) {
        return next(false)
    }
    if (to.path.indexOf('/retrieval')>-1 && roleStaff['archive/report']==0) {
        return next(false)
    }
    if (to.path.indexOf('/recovery')>-1 && roleStaff['archive/file_recycle']==0) {
        return next(false)
    }

    return next()

})

export default router;

