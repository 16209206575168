import { request, config } from '@/utils'

const api = {

    stat (data) {  // 获取档案统计
        return request.post(`${config.apiurl}/stat/panel`, data)
    },
    birthday (data) {  // 获取员工生日提醒
        return request.post(`${config.apiurl}/stat/get_birthday_reminder`, data)
    },
    expire (data) {  // 获取合同到期提醒
        return request.post(`${config.apiurl}/stat/get_contract_expire_reminder`, data)
    },
    retire (data) {  // 获取退休提醒
        return request.post(`${config.apiurl}/stat/get_retire_reminder`, data)
    },
    category (data) {  // 获取项目类别列表接口
        return request.post(`${config.apiurl}/archives/category/list`, data)
    },
    archives (data) {  // 获取档案记录列表
        return request.post(`${config.apiurl}/archives/list`, data)
    },
    everydayArchivesAdd (data) {  // 获取每天的档案新增详情列表
        return request.post(`${config.apiurl}/stat/get_everyday_stat`, data)
    },

    archivesHandleList (data) {  // 获取提档待处理记录
        return request.post(`${config.apiurl}/archives/handle/list`, data)
    },

    archivesHandleDetail (data) {  // 获取提档待处理记录详情
        return request.post(`${config.apiurl}/archives/handle/detail`, data)
    },

    archivesHandle (data) {  // 确认提档接
        return request.post(`${config.apiurl}/archives/handle`, data)
    },

    forbid (data) {  // 拒绝提档接口
        return request.post(`${config.apiurl}/archives/handle/forbid`, data)
    },


    categoryList (data) {  // 获取项目类别列表
        return request.post(`${config.apiurl}/archives/category/list`, data)
    },

    categoryAdd (data) {  // 添加项目类别
        return request.post(`${config.apiurl}/archives/category/add`, data)
    },

    categoryRemove (data) {  // 删除项目类别
        return request.post(`${config.apiurl}/archives/category/remove`, data)
    },

    archivesInfo (data) {   // 获取商务合同档案信息/获取员工档案信息
        return request.post(`${config.apiurl}/archives/info`, data)
    },

    archivesModify (data) {  // 添加或修改商务合同档案/添加或修改员工档案
        return request.post(`${config.apiurl}/archives/modify`, data)
    },

    staffProperty () {  // 获取劳务关系
        return request.post(`${config.apiurl}/archives/staff/property`)
    },
    template (data) {  // 获取档案类型字段扩展项接口
        return request.post(`${config.apiurl}/archives/template`,data)
    },

    archivesSearch (data) {  // 检索接口
        return request.post(`${config.apiurl}/archives/search`, data)
    },

    archivesDetail (data) {  // 获取商务合同档案详情 - 获取员工档案详情
        return request.post(`${config.apiurl}/archives/detail`, data)
    },

    // staffSearch (data) {  // 员工档案检索
    //     return request.post(`${config.apiurl}/archives/staff/search`, data)
    // },

    archivesList (data) {  // 获取档案记录列表
        return request.post(`${config.apiurl}/archives/list`, data)
    },

    archivesLogList (data) {  // 获取档案修改记录
        return request.post(`${config.apiurl}/archives/modification/log/list`, data)
    },

    fileList (data) {  // 附件列表
        return request.post(`${config.apiurl}/archives/file/list/tag`, data)
    },

    getFolderList(data) {//获取标签列表
        return request.post(`${config.apiurl}/archives/tag/list`, data)
    },

    deleteFolder(data) {//删除标签接口
        return request.post(`${config.apiurl}/archives/tag/delete`, data)
    },

    retrieveLogList (data) {  // 查看调档记录
        return request.post(`${config.apiurl}/archives/retrieve/log/list`, data)
    },

    fileDel (data) {  // 批量删除文件
        return request.post(`${config.apiurl}/archives/file/delete`, data)
    },

    fileListUpdate (data) {  // 档案详情修改  档案文件
        return request.post(`${config.apiurl}/archives/file/bind`, data)
    },
    recycle (data) {  // 回收站列表接口
        return request.post(`${config.apiurl}/recycle/list`, data)
    },

    recover (data) {  // 恢复文件接口
        return request.post(`${config.apiurl}/recycle/recover`, data)
    },
    delete (data) {  // 彻底删除附件接口
        return request.post(`${config.apiurl}/recycle/delete`, data)
    },
    red_dot (data) {  // 获取档案提醒红点状态
        return request.post(`${config.apiurl}/stat/red_dot`, data)
    },

    // 员工
    applyLogList (data) {  // 获取档案修改记录
        return request.post(`${config.apiurl}/archives/apply/log/list`, data)
    },

    archivesApply (data) {  // 员工提档申请
        return request.post(`${config.apiurl}/archives/apply`, data)
    },

    // 获取资料清单接口
    getMaterialList(data){
        return request.post(`${config.apiurl}/archives/material`,data)
    },

    // 用于添加扩展选项
    addExtentOption(data){
        return request.post(`${config.apiurl}/archive/extend/option/add`,data)
    },
    // 获取扩展选项列表
    getExtendOptionList(data){
        return request.post(`${config.apiurl}/archive/extend/option/list`,data)
    },
    // 获取扩展选项列表（使用于检索页）
    getExtendOptionList_for_search(data){
        return request.post(`${config.apiurl}/archive/extend/option/list_for_search`,data)
    },
    // 用于删除扩展选项，已使用的选项无法删除
    extendOptionDel(data){
        return request.post(`${config.apiurl}/archive/extend/option/del`,data)
    },




}

export default api;