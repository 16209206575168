import user from './user'
import auth from './auth'
import archives from './archives'

import related_company from './related_company';


export default {
    user,
    auth,
    archives,
    related_company

}